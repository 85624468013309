import { AxiosResponse } from 'axios'
import { QueryFunction } from 'react-query'
import { ConfigValues } from '../../store/types'
import { buildQueryKeys } from '../queryUltils'
import { request } from '../request'

export const BASE_URL = '/calculator/config-values'

export type ConfigResponses = {
  get: AxiosResponse<ConfigValues>
}

type ConfigEndpoints = {
  get: QueryFunction<ConfigResponses['get']>
}

export const Configs: ConfigEndpoints = {
  get: () => request.get(`${BASE_URL}`),
}

export const ConfigsQueryKeys = buildQueryKeys(Configs)
