import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Auth from './pages/Auth'
import { HomePage } from './pages/HomePage/Loadable'
import { NotFoundPage } from './pages/NotFoundPage'

const queryClient = new QueryClient()

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/" component={PrivateRoutes} />
          <Route component={NotFoundPage} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

const PrivateRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path={`?email=:email&code=:code`} component={HomePage} />
        <Redirect to="/" />
      </Switch>
    </>
  )
}
