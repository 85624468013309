import { AxiosError } from 'axios'
import { Auth } from './endpoints/auth'
import { Configs } from './endpoints/configs'

export type ErrorResponse = AxiosError<{
  error?: string
  message: string
  statusCode: number
}>

export const API = {
  Auth,
  // Contracts,
  // Customers,
  // Medias,
  // Payment,
  Configs,
}
