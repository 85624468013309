import { format, toDate } from 'date-fns-tz'
import { toast } from 'react-toastify'
import { ErrorResponse } from './../apis'

const messageCors = 'Server Error. Please try again later.'
export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
export function currencyFormat(num) {
  return '€ ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0
}

export const isEmpty = (value: any) => [undefined, null, ''].includes(value)

export const convertToDate = (date: string) => {
  return date && format(toDate(date), 'dd/MM/yy')
}
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const handleError = (error: ErrorResponse, callback?: () => void) => {
  if (callback) {
    callback()
  }
  const position = 'top-right'
  const errorMessage = error?.response?.data?.message
  if (errorMessage) {
    if (
      error?.response?.data?.statusCode === 401 ||
      error?.response?.data?.statusCode === 403
    ) {
      // dont print anything here
    } else if (error?.response?.status === 500) {
      toast.error(messageCors, { position })
    } else if (Array.isArray(errorMessage)) {
      errorMessage.forEach(err =>
        toast.error(err, {
          position,
        }),
      )
    } else {
      toast.error(errorMessage, {
        position,
      })
    }
  }
}

export function CommaFormatted(amount: any) {
  let delimiter = ','
  let i
  let d
  let a
  if (amount.search('.') > 0) {
    a = amount.split('.', 2)
    d = a[1]
    i = parseInt(a[0])
  } else {
    d = undefined
    i = parseInt(amount)
  }

  if (isNaN(i)) {
    return ''
  }
  let minus = ''
  if (i < 0) {
    minus = '-'
  }
  i = Math.abs(i)
  let n = String(i)
  a = []
  while (n.length > 3) {
    let nn = n.substring(n.length - 3)
    a.unshift(nn)
    n = n.substring(0, n.length - 3)
  }
  if (n.length > 0) {
    a.unshift(n)
  }
  n = a.join(delimiter)
  if ((d && d.length < 1) || !d) {
    amount = n
  } else if (d && d.length >= 1) {
    amount = n + '.' + d
  }
  amount = minus + amount
  return amount
}

const regexCoin = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, ',', '.']
const regexChi = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
export function validateInputNumber(value, type: 'coin' | 'chi') {
  if (type === 'coin') {
    if (!regexCoin.includes(value[value.length - 1])) {
      return value.slice(0, value.length - 1)
    }
    return value
  }
  if (!regexChi.includes(value[value.length - 1])) {
    return value.slice(0, value.length - 1)
  }
  return value
}

export function toFixedNumber(x) {
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split('e-')[1])
    if (e) {
      x *= Math.pow(10, e - 1)
      x = '0.' + new Array(e).join('0') + x.toString().substring(2)
    }
  } else {
    let e = parseInt(x.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      x /= Math.pow(10, e)
      x += new Array(e + 1).join('0')
    }
  }
  return x
}
