import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { routerAuth } from '../../../utils/routerPath'
import HalfLayout from '../../layout/HalfLayout'
import Login from './Login'

const Auth = () => {
  return (
    <HalfLayout paddingTopRightHandSide={15}>
      <Switch>
        <Route path={`${routerAuth.login}`} component={Login} />
        <Redirect to={`${routerAuth.login}`} />
      </Switch>
    </HalfLayout>
  )
}

export default Auth
