import { AccessTokenResponse } from '../store/types'

export const KEYS = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  TIME: 'time',
  EMAIL: 'email',
  EXPIRES_IN: 'expires_in',
}

export type Tokens = Pick<AccessTokenResponse, 'accessToken' | 'refreshToken'>

export const setTokens = ({ accessToken, refreshToken }: Tokens) => {
  localStorage.setItem(KEYS.ACCESS_TOKEN, accessToken)
  localStorage.setItem(KEYS.REFRESH_TOKEN, refreshToken)
}

export const getTokens = (): Tokens => {
  const accessToken = localStorage.getItem(KEYS.ACCESS_TOKEN)
  const refreshToken = localStorage.getItem(KEYS.REFRESH_TOKEN)
  return { accessToken, refreshToken }
}

export const clearTokens = (): void => {
  localStorage.removeItem(KEYS.ACCESS_TOKEN)
  localStorage.removeItem(KEYS.REFRESH_TOKEN)
}

export const setTimeLogin = (time: number): void => {
  localStorage.setItem(KEYS.TIME, time.toString())
}

export const getTimeLogin = (): string => {
  return localStorage.getItem(KEYS.TIME)
}

export const clearTimeLogin = (): void => {
  localStorage.removeItem(KEYS.TIME)
}

export const setEmail = (email: string): void => {
  localStorage.setItem(KEYS.EMAIL, email)
}

export const getEmail = (): string => {
  return localStorage.getItem(KEYS.EMAIL)
}

export const clearEmail = (): void => {
  localStorage.removeItem(KEYS.EMAIL)
}

export const setExpiresIn = (value: number): void => {
  localStorage.setItem(KEYS.EXPIRES_IN, value.toString())
}

export const getExpiresIn = (): string => {
  return localStorage.getItem(KEYS.EXPIRES_IN)
}

export const clearExpiresIn = (): void => {
  localStorage.removeItem(KEYS.EXPIRES_IN)
}
