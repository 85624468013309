import { Container, useMediaQuery } from '@mui/material'
import { Box, styled, useTheme } from '@mui/system'
import React from 'react'

interface IHalfLayout {
  paddingTopRightHandSide?: number
  title?: string
}

const HalfLayout: React.FC<IHalfLayout> = props => {
  const theme = useTheme()
  const matchXs = useMediaQuery(theme.breakpoints.only('xs'))
  return (
    <SurverWrapper>
      <LeftCol>
        {!matchXs && (
          <>
            <MainLeftTitle>{props.title}</MainLeftTitle>
          </>
        )}
      </LeftCol>
      <RightCol>
        <Container
          component="main"
          maxWidth={'sm'}
          style={matchXs ? { paddingLeft: 0, paddingRight: 0 } : {}}
        >
          <Box
            sx={{
              flex: 1,
              marginTop: props?.paddingTopRightHandSide | (matchXs ? 3 : 10),
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {props.children}
          </Box>
        </Container>
      </RightCol>
    </SurverWrapper>
  )
}

export default HalfLayout

const MainLeftTitle = styled('h1')`
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 76px;
`

const SurverWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  height: '100vh',
  [theme.breakpoints.only('xs')]: {
    gridTemplateColumns: '100%',
    gridTemplateRows: '0% 100%',
    height: '100%',
    minHeight: '-webkit-fill-available',
  },
}))

const LeftCol = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.only('xs')]: {},
}))
const RightCol = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
}))
