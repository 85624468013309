import { AxiosResponse } from 'axios'
import { MutationFunction } from 'react-query'
import {
  AccessTokenResponse,
  ChangePasswordDto,
  CheckKycOrTCResponse,
  ContractDetailsResponse,
  CreateTermAndCondition,
  EmailLogin,
  EmailVerify,
  ListAllPrice,
  LoginDto,
  ResetPasswordDto,
} from '../../store/types'
import { buildQueryKeys, ResponseType } from '../queryUltils'
import { request } from '../request'

const BASE_URL = '/auth'

export class ReceiverAddress {
  bsc: string
  ethereum: string
}

type AuthEndpoints = {
  login: MutationFunction<AxiosResponse<AccessTokenResponse>, LoginDto>
  resetPassword: MutationFunction<AxiosResponse<void>, ResetPasswordDto>
  changePassword: MutationFunction<AxiosResponse<void>, ChangePasswordDto>
  emailLogin: MutationFunction<AxiosResponse<void>, EmailLogin>
  verifyEmail: MutationFunction<AxiosResponse<AccessTokenResponse>, EmailVerify>
  checkEmailWhitelist: MutationFunction<AxiosResponse<void>, string>
  checkKycOrTC: MutationFunction<AxiosResponse<CheckKycOrTCResponse>, string>
  contractDetail: MutationFunction<
    AxiosResponse<ContractDetailsResponse>,
    string
  >
  createTermAndCondition: MutationFunction<
    AxiosResponse<void>,
    CreateTermAndCondition
  >
  chiPrice: MutationFunction<AxiosResponse<string>>
  ethPrice: MutationFunction<AxiosResponse<string>>
  daiPrice: MutationFunction<AxiosResponse<string>>
  bnbPrice: MutationFunction<AxiosResponse<string>>
  usdcPrice: MutationFunction<AxiosResponse<string>>
  usdtPrice: MutationFunction<AxiosResponse<string>>
  wbtcPrice: MutationFunction<AxiosResponse<string>>
  bonusPercent: MutationFunction<AxiosResponse<any>, string>
  receiverAdd: MutationFunction<AxiosResponse<ReceiverAddress>>
  listFundsTransferred: MutationFunction<AxiosResponse<any>, string>
  listTokenPrice: MutationFunction<AxiosResponse<ListAllPrice>>
}

export class TokenNetwork {
  blockchain: string
  address: string
}

export const ETH: TokenNetwork = {
  blockchain: 'ethereum',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
}

export const DAI: TokenNetwork = {
  blockchain: 'ethereum',
  address: '0x6b175474e89094c44da98b954eedeac495271d0f',
}

export const USDC: TokenNetwork = {
  blockchain: 'ethereum',
  address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
}

export const BNB: TokenNetwork = {
  blockchain: 'bsc',
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
}

export const USDT: TokenNetwork = {
  blockchain: 'ethereum',
  address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
}

export const WBTC: TokenNetwork = {
  blockchain: 'ethereum',
  address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
}

export const Auth: AuthEndpoints = {
  login: payload => request.post(`${BASE_URL}/login`, payload),
  resetPassword: payload => request.post(`${BASE_URL}/reset-password`, payload),
  changePassword: payload =>
    request.put(`${BASE_URL}/change-password`, payload),
  emailLogin: payload => request.post('/twilio/send', payload),
  verifyEmail: payload => request.post('/twilio/verify', payload),
  checkEmailWhitelist: payload =>
    request.get(`/activeCampaigns/get-whitelist-email?email=${payload}`),
  checkKycOrTC: payload =>
    request.get(`/activeCampaigns/get-key-or-tc-email?email=${payload}`),
  contractDetail: payload =>
    request.get(`/activeCampaigns/contact-detail?email=${payload}`),
  createTermAndCondition: payload =>
    request.post('/activeCampaigns/create-term-condition', payload),
  chiPrice: () => request.get(`/depay/chi-price`),
  ethPrice: () =>
    request.get(`/depay/token-price/${ETH.blockchain}/${ETH.address}`),
  daiPrice: () =>
    request.get(`/depay/token-price/${DAI.blockchain}/${DAI.address}`),
  bnbPrice: () =>
    request.get(`/depay/token-price/${BNB.blockchain}/${BNB.address}`),
  usdcPrice: () =>
    request.get(`/depay/token-price/${USDC.blockchain}/${USDC.address}`),
  usdtPrice: () =>
    request.get(`/depay/token-price/${USDT.blockchain}/${USDT.address}`),
  wbtcPrice: () =>
    request.get(`/depay/token-price/${WBTC.blockchain}/${WBTC.address}`),
  bonusPercent: payload => request.get(`/depay/bonus?email=${payload}`),
  receiverAdd: () => request.get(`/depay/receiver`),
  listFundsTransferred: payload =>
    request.get(`/activeCampaigns/list-funds-transferred?email=${payload}`),
  listTokenPrice: () => request.get(`/depay/all-token-price`),
}

export const AuthQueryKeys = buildQueryKeys(Auth)
export type AuthResponses = ResponseType<AuthEndpoints>
