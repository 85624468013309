import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfigValues } from '../../types'

export type ConfigsState = ConfigValues

const initialState: ConfigsState = {
  annualFee: 29,
  allowance: 1.02,
  administrationFee: 1.02,
  firstRegistration: 0,
  clubMembership: 0,
  damageWaiver: 0,
  yearly: 185,
  interestRate: 0.5,
}

const configsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {
    setState: (state, { payload }: PayloadAction<ConfigValues>) => ({
      ...state,
      ...payload,
    }),
  },
})

const { actions, reducer: configsReducer } = configsSlice

export { actions }

export const useConfigsSlice = () => {
  return { actions }
}

export default configsReducer
