import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getTokens } from '../../../utils/localStorage'

export type AuthState = {
  authorized: boolean
  email: string
  minimumInvestment: number
  isPrivateSale: boolean
  isInterval: boolean
}

const { accessToken } = getTokens()

const initialState: AuthState = {
  authorized: !!accessToken,
  email: '',
  minimumInvestment: 0,
  isPrivateSale: false,
  isInterval: true,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: state => {
      state.authorized = true
    },
    signOut: state => {
      state.authorized = false
      state.email = ''
      state.isPrivateSale = false
      state.minimumInvestment = 0
    },
    email: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setMinimumInvestment: (state, action: PayloadAction<number>) => {
      state.minimumInvestment = action.payload
    },
    setPrivateSale: (state, action: PayloadAction<boolean>) => {
      state.isPrivateSale = action.payload
    },
    setInterval: (state, action: PayloadAction<boolean>) => {
      state.isInterval = action.payload
    },
  },
})

const { actions, reducer: authReducer } = authSlice

export { actions }

export const useAuthSlice = () => {
  // useInjectReducer({ key: authSlice, reducer: authReducer })
  return { actions }
}

export default authReducer
