import axios from 'axios'
import { store } from '../store'
import { useAuthSlice } from '../store/slices/auth'
import { AccessTokenResponse } from '../store/types'
import {
  clearEmail,
  clearExpiresIn,
  clearTimeLogin,
  clearTokens,
  getTokens,
  setExpiresIn,
  setTimeLogin,
  setTokens,
} from '../utils/localStorage'

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
})

let checkTimeout = false

const refreshAccessToken = async (): Promise<string | undefined> => {
  const { refreshToken } = getTokens()
  try {
    if (!refreshToken) {
      return
    }
    const { data } = await request.post<AccessTokenResponse>(
      '/auth/refresh-token',
      {
        refreshToken,
      },
    )
    if (!data) {
      return
    }
    setTokens(data)
    setTimeLogin(new Date().getTime())
    setExpiresIn(data.refreshExpiresIn)
    return data.accessToken
  } catch (err) {
    return
  }
}

export const clearLocalStorage = () => {
  clearTokens()
  clearTimeLogin()
  clearEmail()
  clearExpiresIn()
}

request.interceptors.request.use(configs => {
  const { accessToken } = getTokens()
  if (!checkTimeout) {
    return {
      ...configs,
      headers: {
        ...(configs.headers || {}),
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
    }
  }
})

request.interceptors.response.use(
  res => {
    checkTimeout = false
    return res
  },
  async error => {
    const originalRequest = error.config
    if (
      [401, 403].includes(error?.response?.status) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      const accessToken = await refreshAccessToken()
      if (!accessToken) {
        checkTimeout = true
        clearLocalStorage()
        return
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      return request(originalRequest)
    } else if (
      [400].includes(error?.response?.status) &&
      error?.response?.data?.message === 'Refresh token expired'
    ) {
      const { actions } = useAuthSlice()
      store.dispatch(actions.signOut())
      checkTimeout = true
      clearLocalStorage()
    }
    return Promise.reject(error)
  },
)
