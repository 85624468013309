import { createSlice, PayloadAction } from '@reduxjs/toolkit'
type InternalData = {
  page: number
  itemsPerPage: number
  total: number
}
const DEFAULT_PAGE_COUNT = 10

const initialState: InternalData = {
  page: 0,
  itemsPerPage: DEFAULT_PAGE_COUNT,
  total: 0,
}

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPagination: (
      state,
      { payload }: PayloadAction<Partial<InternalData>>,
    ) => ({
      ...state,
      ...payload,
    }),
  },
})

export const { actions: paginationActions, reducer: paginationReducer } =
  paginationSlice

export const usePaginationSlice = () => {
  return { actions: paginationActions }
}

export default paginationReducer
