/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { useAuthSlice } from '../../../store/slices/auth'
import { clearTokens } from '../../../utils/localStorage'
import { A } from '../../components/A'

export function Nav() {
  const { actions } = useAuthSlice()
  const dispatch = useDispatch()

  const handleLogout = () => {
    clearTokens()
    dispatch(actions.signOut())
  }

  return (
    <Wrapper>
      <A onClick={handleLogout}>Logout</A>
    </Wrapper>
  )
}

const center = `
  display: flex;
  justify-items: center;
  align-items: center;
`
const Wrapper = styled.nav`
  ${center}
`
