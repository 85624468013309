import styled from 'styled-components/macro'

export const A = styled.a`
  color: ${p => p.theme.primaryDark};
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.text};
    opacity: 0.8;
  }

  &:active {
    opacity: 0.4;
  }
`
