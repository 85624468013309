import { Loop } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { ButtonProps } from '@mui/material'
import React, { FC } from 'react'
import styled, { css } from 'styled-components/macro'

export type ButtonLoadingProps = ButtonProps & { loading?: boolean }

export const Button: FC<ButtonLoadingProps> = ({
  loading,
  children,
  ...props
}) => {
  return (
    <LoadingButton
      loading={loading}
      loadingPosition={loading ? 'end' : undefined}
      endIcon={loading && <Loop />}
      {...props}
    >
      {children}
    </LoadingButton>
  )
}

const styleButton = css`
  min-width: 97px;
  border-radius: 4px;
  font-size: 16px;
  padding: 0;
  transition: All 0.3s;
  &:focus {
    outline: none;
  }
`

export const PrimaryButton = css`
  ${styleButton}
  background: ${p => p.theme.primary};
  color: white;
  border: 1px solid white;
  height: 41px;

  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: ${p => p.theme.primary};
    border: 1px solid ${p => p.theme.primary};
  }
`

export const ButtonAdd = styled.button`
  ${styleButton}
  height: 54px;
  padding: 0 38px;
  background: ${p => p.theme.primary};
  color: white;
  border: 1px solid white;
  &:hover,
  &:focus {
    background: transparent;
    color: ${p => p.theme.primary};
    border: 1px solid ${p => p.theme.primary};
  }
`
export const ButtonTable = styled.button`
  background: transparent;
  color: ${p => p.theme.primary};
  border: 1px solid ${p => p.theme.primary};
  height: fit-content;
  padding: 8px 7px;
  font-size: 14px;
  border-radius: 4px;
  transition: All 0.3s;
  &:hover,
  &:focus {
    outline: none;
    background: ${p => p.theme.primary};
    color: white;
    border: 1px solid white;
  }
`

export const ButtonBack = styled.button`
  ${PrimaryButton}
  background-color: ${p => p.theme.colorErrorRed};
  min-width: 143px;
`
export const ButtonExit = styled.button`
  background: #ececec;
  cursor: pointer;
  border-radius: 100px;
  color: #171717;
  border: 1px solid transparent;
  height: 31px;
  transition: All 0.3s;
  padding: 0px 23px;
  font-size: 14px;
  font-family: 'SF ProDisplay';
  &:hover,
  &:focus {
    background: #ececec;
    color: #171717;
    opacity: 0.6;
    border: 1px solid white;
  }
`
