import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
const logo = require('./assets/logo.png')

export function Logo() {
  return (
    <Link to={'/'}>
      <Wrapper>
        <img src={logo.default} alt="logo" />
      </Wrapper>
    </Link>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
